







































































import { Component, Vue } from 'vue-property-decorator';
import { minuteModule } from '@/store/modules/minute';

@Component
export default class ValidationMinute extends Vue {
    states: number[] = [];
    step = 1;
    minute = null;
    user = {};

    get successMessage() {
        return this.state === '1'
            ? 'Se ha validado el "detalle de facturación"'
            : 'No se ha validado la información del "detalle de facturación"';
    }

    get state() {
        return this.$route.params.state;
    }

    async mounted() {
        try {
            this.user = await minuteModule.validationMinute({
                id: this.$route.params.id,
                state: parseInt(this.$route.params.state)
            });
        } catch (err) {
            this.states = err.states || [];
        }
    }

    get error() {
        if (this.states.includes(1) && this.state === '1') {
            return 'No puedes validar un detalle facturación ya confirmado';
        } else if (this.states.includes(2) && this.state === '1') {
            return 'No puedes validar un detalle facturación ya invalidada';
        } else if (this.states.includes(1) && this.state === '2') {
            return 'No puedes invalidar un detalle facturación ya confirmada';
        } else if (this.states.includes(2) && this.state === '2') {
            return 'No puedes invalidar un detalle facturación ya invalidada';
        }
        return '';
    }
}

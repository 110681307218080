import { VuexModule, Module, Action } from 'vuex-class-modules';
import Axios from 'axios';
import store from '@/store';
import { FilterAppointment } from '@/interfaces/filter';

@Module({ generateMutationSetters: true })
export class MinuteModule extends VuexModule {
    dialog = false;

    response = {
        data: [],
        total: 0,
        page: 1
    };

    filter: FilterAppointment = {
        state: 'incompleta',
        typeFilter: undefined,
        filterValue: undefined,
        page: 1
    };

    @Action
    async closeOrOpenDialog() {
        this.dialog = !this.dialog;
    }

    @Action
    async setFilter(data: FilterAppointment) {
        this.filter = Object.assign(this.filter, data);
    }

    @Action
    async validateBilling(data: { state: number; id: string }) {
        await Axios.put(`minutes/change-state/${data.id}`, data);
    }

    @Action
    async validateDataPayment(id: string) {
        await Axios.post(
            `minutes/validate`,
            { id },
            { params: { ignoreLoading: true } }
        );
    }

    @Action
    async index(ignore?: boolean) {
        const params = this.filter;
        if (ignore) {
            const ignoreLoading = { ignoreLoading: true };
            Object.assign(params, ignoreLoading);
        }
        const { data } = await Axios.get('minutes/filter', {
            params
        });
        this.response = {
            data: data.minutas,
            total: data.totalDocs,
            page: data.page
        };
    }

    @Action
    async validationMinute(body: { id: string; state: number }) {
        const { data } = await Axios.put(`minutes/change-state/${body.id}`, {
            state: body.state
        });
        return data;
    }
}
export const minuteModule = new MinuteModule({
    store,
    name: 'minute'
});
